import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';
import {
  TagColor,
  TagMode,
  TagSize,
} from '@/components/ui/Tag/Tag.typedefs';

export enum TagHarnessSelectors {
  DataQa = 'platform_tag',
}

export abstract class TagHarness extends ComponentHarness {
  dataQa: string = TagHarnessSelectors.DataQa;

  assertContainsText(text: string) {
    this.getElement().contains(text);
  }

  assertHasSize(size: TagSize) {
    this.getElement().should('satisfy', ($el: JQuery<HTMLElement>) => {
      const classes = Array.from($el[0]?.classList ?? []);

      return classes.some(
        (className) => className.includes(`container--size-${size}`),
      );
    });
  }

  assertHasColorMode(color: TagColor, mode: TagMode) {
    this.getElement().should('satisfy', ($el: JQuery<HTMLElement>) => {
      const classes = Array.from($el[0]?.classList ?? []);

      return classes.some(
        (className) => className.includes(`container--color-${color}-${mode}`),
      );
    });
  }

  assertHasIcon(iconDataQa?: string) {
    if (!iconDataQa) {
      this.getElement().find('.icon').should('exist');

      return;
    }

    this.getNestedElement(iconDataQa).should('exist');
  }

  assertNoIcon() {
    this.getElement().find('.icon').should('not.exist');
  }

  abstract assertRenderedCorrectly(...args: any[]): void;
}
