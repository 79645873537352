import React, {
  FC,
  memo,
  CSSProperties,
} from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  AchievementIcon,
  AchievementItemProps,
  achievementItemCSSVariables,
} from '@/components/platform/Achievements';
import { AchievementItemHarnessSelectors } from '@/components/platform/Achievements/AchievementItem.harness';
import { isMobileSafari } from '@/lib/helpers/isMobileSafari';
import { isDesktopSafari } from '@/lib/helpers/isDesktopSafari';
import { AchievementTag } from '@/components/platform/Achievements/components/AchievementTag';
import { AchievementRarity } from '@/controllers/graphql/generated';
import { AchievementTagType } from '@/components/platform/Achievements/typedefs';
import styles from './AchievementItem.module.scss';

export const AchievementItem: FC<AchievementItemProps> = memo(({
  achievement,
  userAchievement,
  showToolTip = true,
  textTruncated = true,
  showName = true,
  showNewBadge = true,
  showRarityBadge = true,
  showCategory = true,
  blocked,
  customIcon,
  className,
  iconWidth,
  iconHeight,
  withShadow,
  shadowProps,
  ...props
}) => {
  const { t } = useTranslation([I18N_CODES.achievements]);

  const isToolipShown = showToolTip && !!achievement?.name;

  const isNameShown = showName && !!achievement?.name;

  const isCategoryShown = showCategory && !!achievement?.category;

  const isNewAchievement = !!userAchievement?.isNew;

  const isNewTagShown = !blocked && showNewBadge && isNewAchievement;

  const isRarityTagShown = !blocked
    && showRarityBadge
    && achievement?.rarity !== AchievementRarity.Common;

  const isTagShown = isNewTagShown || isRarityTagShown;

  const achievementTagType = isNewTagShown
    ? AchievementTagType.New
    : AchievementTagType.Rare;

  const isAchievementUnlocked = !blocked
    && userAchievement?.unlocked;

  const isSafariBrowser = isMobileSafari() || isDesktopSafari();

  const shouldRenderShadow = withShadow && !isSafariBrowser;

  return (
    <div
      data-qa={AchievementItemHarnessSelectors.DataQa}
      data-qa-unlocked={isAchievementUnlocked}
      className={cn(
        className,
        styles.block,
      )}
      style={achievementItemCSSVariables as CSSProperties}
      {...props}
    >
      <ToolTip
        text={(
          isToolipShown
            ? t(`${I18N_CODES.achievements}:name.${achievement?.name}`)
            : ''
        )}
        widthMode={ToolTipWidthMode.Max}
        isManualVerticalPositioning
        isVisibleOnHover={isToolipShown}
      >
        <AchievementIcon
          name={achievement?.name}
          iconLockedUrl={achievement?.iconLocked?.url}
          iconLockedDarkUrl={achievement?.iconLockedDark?.url}
          iconUnlockedUrl={achievement?.iconUnlocked?.url}
          unlocked={isAchievementUnlocked}
          customIcon={customIcon}
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          withShadow={shouldRenderShadow}
          shadowProps={shadowProps}
        />
      </ToolTip>

      {isNameShown && (
        <h4
          data-qa={AchievementItemHarnessSelectors.Name}
          className={cn(
            typography.platformH4,
            styles.name,
            {
              [styles.textTruncate]: textTruncated,
              [styles.locked]: !isAchievementUnlocked,
              [styles.withMargin]: !shouldRenderShadow,
            },
          )}
        >
          {t(`${I18N_CODES.achievements}:name.${achievement?.name}`)}
        </h4>
      )}

      {isCategoryShown && (
        <h5
          data-qa={AchievementItemHarnessSelectors.Category}
          className={cn(
            typography.platformH5,
            styles.category,
            {
              [styles.textTruncate]: textTruncated,
              [styles.locked]: !isAchievementUnlocked,
            },
          )}
        >

          {t(`${I18N_CODES.achievements}:category.${achievement?.category}`)}
        </h5>
      )}

      {isTagShown && (
        <AchievementTag
          tagType={achievementTagType}
        />
      )}
    </div>
  );
});
