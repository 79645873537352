import React, { FC } from 'react';
import capitalize from 'lodash/capitalize';
import { Tag } from '@/components/ui/Tag';
import { AchievementTagType } from '@/components/platform/Achievements/typedefs';
import { AchievementTagHarnessSelectors } from '@/components/platform/Achievements/components/AchievementTag.harness';
import styles from './AchievementTag.module.scss';

interface Props {
  tagType: AchievementTagType;
}

export const AchievementTag: FC<Props> = ({ tagType }) => {
  const colorMode = tagType === AchievementTagType.New
    ? Tag.color.Brand
    : Tag.color.Warning;

  const tagText = capitalize(tagType);

  const dataQa = tagType === AchievementTagType.New
    ? AchievementTagHarnessSelectors.New
    : AchievementTagHarnessSelectors.Rare;

  return (
    <Tag
      dataQa={dataQa}
      text={tagText}
      size={Tag.size.Small}
      color={colorMode}
      className={styles.badge}
    />
  );
};
